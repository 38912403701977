import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PatientOrderHistoryResponse } from '@patient-ui/shared/models';
import { EstimateRequest, EstimateResponse } from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

@Injectable({
  providedIn: 'root',
})
export class PatientOrderService {
  constructor(
    private readonly http: HttpClient,
    private envService: EnvironmentService
  ) {}

  getPatientOrders(): Observable<PatientOrderHistoryResponse> {
    const url = `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/orders/openOrders`;
    return this.http.get<PatientOrderHistoryResponse>(url);
  }

  getPatientEstimates(payload: EstimateRequest): Observable<EstimateResponse> {
    const url = `${this.envService.baseUrl}/protected/patients/current/linkedAccounts/orders/estimate`;
    return this.http.post<EstimateResponse>(url, payload);
  }
}
