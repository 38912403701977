import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { EstimateResponse, OrderEntity } from '@patient-ui/shared/models';

import * as PatientOrdersActions from './patient-orders.actions';
import { PatientOrderService } from './patient-orders.service';

@Injectable()
export class PatientOrdersEffects {
  constructor(
    private actions$: Actions,
    private patientOrderService: PatientOrderService
  ) {}

  loadPatientOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientOrdersActions.loadPatientOrders),
      exhaustMap(() =>
        this.patientOrderService.getPatientOrders().pipe(
          map((response) => {
            const oList: OrderEntity[] = [];
            if (response.orders) {
              for (const order of response.orders) {
                const newOrder = new OrderEntity(order);
                oList.push(newOrder);
              }
            }
            return PatientOrdersActions.loadPatientOrdersSuccess({
              patientOrders: oList.sort((a, b) => b.orderId - a.orderId),
            });
          }),
          catchError((_error) =>
            of(PatientOrdersActions.loadPatientOrdersFailure())
          )
        )
      )
    )
  );

  loadPatientEstimates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientOrdersActions.loadPatientEstimates),
      exhaustMap((action) =>
        this.patientOrderService.getPatientEstimates(action.estimates).pipe(
          map((estimates: EstimateResponse) =>
            PatientOrdersActions.loadPatientEstimatesSuccess({ estimates })
          ),
          catchError((_error) =>
            of(PatientOrdersActions.loadPatientEstimatesFailure())
          )
        )
      )
    )
  );
}
