export interface PatientName {
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  gender: string;
  lpid: string;
}

export interface PhysicianName {
  firstName: string;
  middleName: string;
  lastName: string;
}
export interface OrderCode {
  testNumber: string;
  testName: string | null;
}
export interface PrimaryInsurance {
  policyID: string;
  planName: string;
}

export class OrderEntity {
  pid: number;
  orderDate: string;
  pwnOrderId: string;
  orderedTests: string;
  firstName: string;
  lastName: string;
  requisitionNumber: string;
  patient: PatientName;
  physician: PhysicianName;
  orderCodes: OrderCode[];
  primaryInsurance: PrimaryInsurance;
  onDemandOrder: boolean;
  accountNumber: string;

  constructor(orderEntity: OrderEntity) {
    this.pid = orderEntity.pid;
    this.orderDate = orderEntity.orderDate;
    this.pwnOrderId = orderEntity.pwnOrderId;
    this.orderedTests = orderEntity.orderedTests;
    this.firstName = orderEntity.firstName;
    this.lastName = orderEntity.lastName;
    this.requisitionNumber = orderEntity.requisitionNumber;
    this.patient = orderEntity.patient;
    this.physician = orderEntity.physician;
    this.orderCodes = orderEntity.orderCodes;
    this.primaryInsurance = orderEntity.primaryInsurance;
    this.onDemandOrder = orderEntity.onDemandOrder;
    this.accountNumber = orderEntity.accountNumber;
  }

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get patientName(): string {
    return this.patient.firstName + ' ' + this.patient.lastName;
  }

  get orderNumber(): string {
    return this.requisitionNumber;
  }
  get orderBy(): string {
    return `Dr ${this.physician.firstName} ${this.physician.lastName}`;
  }
  get isOnDemand(): boolean {
    return this.onDemandOrder;
  }

  get testName(): string {
    switch (this.orderedTests) {
      case '164055': {
        return 'COVID-19 IgG antibody test';
      }
      case '164090': {
        return 'COVID-19 Semi-Quantitative Antibody Test';
      }
      default: {
        return 'Unknown Test';
      }
    }
  }

  get orderId(): number {
    return parseInt(this.pwnOrderId, 10);
  }
}
