import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromPatientOrders from './patient-orders.reducer';

export const patientOrdersStateSelector = createFeatureSelector<
  fromPatientOrders.PatientOrdersState
>(fromPatientOrders.patientOrdersFeatureKey);

export const selectPatientOrdersPayloadStatus = createSelector(
  patientOrdersStateSelector,
  (state: fromPatientOrders.PatientOrdersState) =>
    state.loadPatientOrdersPayloadRequestStatus
);

export const selectPatientOrders = createSelector(
  patientOrdersStateSelector,
  (state: fromPatientOrders.PatientOrdersState) => state.patientOrders
);

export const selectPatientEstimates = createSelector(
  patientOrdersStateSelector,
  (state: fromPatientOrders.PatientOrdersState) => state.patientEstimate
);

export const selectPatientEstimatesPayloadStatus = createSelector(
  patientOrdersStateSelector,
  (state: fromPatientOrders.PatientOrdersState) =>
    state.loadPatientEstimatesPayloadRequestStatus
);

export const dashboardPatientOrdersQuery = {
  patientOrdersStateSelector,
  selectPatientOrdersPayloadStatus,
  selectPatientOrders,
  selectPatientEstimates,
  selectPatientEstimatesPayloadStatus,
};
