import { createAction, props } from '@ngrx/store';

import {
  EstimateRequest,
  EstimateResponse,
  OrderEntity,
} from '@patient-ui/shared/models';

export const loadPatientOrders = createAction('[Orders] Load Patient Orders');

export const loadPatientOrdersSuccess = createAction(
  '[Orders] Load Patient Orders Success',
  props<{ patientOrders: OrderEntity[] }>()
);

export const loadPatientOrdersFailure = createAction(
  '[Orders] Load Patient Orders Failure'
);

export const loadPatientEstimates = createAction(
  '[Estimates] Load Patient Estimates',
  props<{ estimates: EstimateRequest }>()
);

export const loadPatientEstimatesSuccess = createAction(
  '[Estimates] Load Patient Estimates Success',
  props<{ estimates: EstimateResponse }>()
);

export const loadPatientEstimatesFailure = createAction(
  '[Estimates] Load Patient Estimates Failure'
);

export const PatientOrdersActions = {
  loadPatientOrders,
  loadPatientOrdersSuccess,
  loadPatientOrdersFailure,
  loadPatientEstimates,
  loadPatientEstimatesSuccess,
  loadPatientEstimatesFailure,
};
